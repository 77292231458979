<template>
    <section>
        <b-form>
            <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div class="col-span-8">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 lg:col-span-12">
                            <input-required
                            v-model="value.nomeEnsaio"
                            label="Nome"
                            max="50"
                            disabled="true"
                            />
                        </div>
                        <div class="col-span-12 xl:col-span-4">
                            <Dropdown 
                            :Items="dropdownItemsFarmacopeia"
                            required="required"
                            v-model="value.farmacopeiaId"
                            label="Farmacopeia"
                            disabled="true"
                            />
                        </div>
                        <div class="col-span-12 xl:col-span-8">
                            <input-required
                            v-model="value.descricaoEnsaio"
                            label="Descrição"
                            max="50"
                            disabled="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </b-form>
    </section>
</template>

<script>
import { BForm } from "bootstrap-vue";

import InputRequired from "@core/components/commons/inputs/InputRequired";
import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";

export default {
    name: 'GeneralEnsaioInformation',
    components: {
        BForm,
        InputRequired,
        Dropdown,
    },
    props: {
        value: {
        type: Object,
        }
    },
    data() {
        return {
            dropdownItemsFarmacopeia: [],
        }
    },
    created () {
        this.ObterSeletorFarmacopeia();
    },
    methods: {
        ObterSeletorFarmacopeia () {
            this.$http({
                url: `/Farmacopeia/obter-seletor-Farmacopeia/`,
                method: "GET"
            }).then((resposta) => {
                this.dropdownItemsFarmacopeia = resposta.data;
            })
        },
        adicionarDescricao(item) {
            this.$http({
                url: `/Farmacopeia/obter/${item.id}`,
                method: "GET"
            }).then((response) => {
                this.value.nomeEnsaio = response.data.nomeFarmacopeia;
            })
        }
    }
}
</script>
  